import classNames from 'clsx';
import PropTypes from 'prop-types';

import PillButton from './PillButton';

import css from './pill-button.module.scss';

const PremiumGoldButton = ({ className, ...otherProps }) => (
  <PillButton
    className={classNames(className, css.premiumGold)}
    {...otherProps}
  />
);

PremiumGoldButton.propTypes = {
  className: PropTypes.string,
};

export default PremiumGoldButton;
