import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';

import cssVariables from '../../../styles/variables';

import css from './checked-input.module.scss';

class CheckedInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    dataTestId: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.element,
    ]).isRequired,
    type: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    defaultChecked: PropTypes.bool,
    formFieldMeta: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    className: PropTypes.string,
    spanClassName: PropTypes.string.isRequired,
    iconClassName: PropTypes.string.isRequired,
    style: PropTypes.object,
    spanStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    labelClassName: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    labelDataTestId: PropTypes.string,
    isBold: PropTypes.bool,
    autoFocus: PropTypes.bool,
    ariaLabelledBy: PropTypes.string,
    disabled: PropTypes.bool,
  };

  state = {
    isFocused: false,
  };

  handleBlur = (event) => {
    const { onBlur, disabled } = this.props;

    if (disabled) {
      return;
    }

    if (onBlur) {
      onBlur(event);
    }

    this.setState({ isFocused: false });
  };

  handleFocus = (event) => {
    const { onFocus, disabled } = this.props;

    if (disabled) {
      return;
    }

    if (onFocus) {
      onFocus(event);
    }

    this.setState({ isFocused: true });
  };

  render() {
    const {
      id,
      dataTestId,
      name,
      label,
      type,
      handleClick,
      defaultChecked,
      formFieldMeta,
      value,
      className,
      spanClassName,
      iconClassName,
      style,
      spanStyle,
      labelStyle,
      labelClassName,
      labelDataTestId,
      isBold,
      autoFocus,
      ariaLabelledBy,
      disabled,
    } = this.props;

    const { isFocused } = this.state;

    const showErrorState =
      formFieldMeta && formFieldMeta.touched && formFieldMeta.invalid;

    const finalLabelStyle = !isBold
      ? labelStyle
      : {
          ...labelStyle,
          fontFamily: cssVariables['--primary-font-med'],
        };

    return (
      <div
        className={classNames(css.container, className, {
          [css.focus]: !disabled && isFocused,
        })}
        style={style}
      >
        <label
          className={classNames(css.label, labelClassName, {
            [css.disabled]: disabled,
          })}
          style={finalLabelStyle}
          data-error={showErrorState}
          data-testid={labelDataTestId}
        >
          <span
            className={spanClassName}
            style={spanStyle}
            data-error={showErrorState}
          >
            <i
              className={iconClassName}
              style={{
                opacity: defaultChecked ? 1 : 0,
              }}
            />
          </span>
          <input
            className={css.input}
            type={type}
            id={id}
            data-testid={dataTestId}
            name={name}
            defaultChecked={defaultChecked}
            value={value}
            onClick={handleClick}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            autoFocus={autoFocus}
            aria-labelledby={ariaLabelledBy}
            disabled={disabled}
          />
          {/* TODO: Utilize `Text` here when all components that utilize this one are localized */}
          {label}
        </label>
      </div>
    );
  }
}

export default CheckedInput;
