import generateOriginLabelFromQuery from './generateOriginLabelFromQuery';

/**
 *
 * @param getLocalizedText {function}
 * @param location {object}
 * @param defaultResourceKey {string}
 * @returns {string}
 * This function will check if there is localization available for the source override.
 * If it does, it will return the source override resourceKey. Else, default/fallback key.
 */
export default function getOverrideOrDefaultResourceKey(
  getLocalizedText,
  location,
  defaultResourceKey,
) {
  const source = generateOriginLabelFromQuery(location);

  if (!source) {
    return defaultResourceKey;
  }

  const overrideResourceKey = `${defaultResourceKey}.${source}`;
  const queryResourceText = getLocalizedText(overrideResourceKey) || null;
  return queryResourceText && overrideResourceKey !== queryResourceText
    ? overrideResourceKey
    : defaultResourceKey;
}
