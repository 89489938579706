import classNames from 'clsx';
import PropTypes from 'prop-types';

import withCheckedInput from '../../../decorators/form/withCheckedInput';

import cssVariables from '../../../styles/variables';
import CheckedInput from './CheckedInput';
import css from './check-box.module.scss';

const finalSpanStyle = {
  backgroundColor: cssVariables['--secondary-color-5'],
  border: '1px solid transparent',
};

const CheckBox = (props) => (
  <CheckedInput
    spanClassName={classNames(css.span, props.customSpanClass)}
    spanStyle={props.defaultChecked ? finalSpanStyle : {}}
    iconClassName={classNames(css.checkmark, props.customCheckmarkClass, {
      [props.customCheckmarkUncheckedClass]: !props.checked,
    })}
    type="checkbox"
    {...props}
  />
);

CheckBox.propTypes = {
  defaultChecked: PropTypes.bool,
  customSpanClass: PropTypes.string,
  customCheckmarkClass: PropTypes.string,
  customCheckmarkUncheckedClass: PropTypes.string,
};

export default withCheckedInput(CheckBox);
