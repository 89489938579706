import classnames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect } from 'react-redux';
import { PAYMENT_FLOW_FORM_LAYOUT_ALT_PAYMENT_TEXT } from 'src/common/constants/localizations/payment';
import paymentTypes from 'src/common/constants/subscription/paymentTypes';
import { DEVICE } from '../../constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectPartners } from '../../selectors/partners';
import cssVariables from '../../styles/variables';
import findPartnerInfo from '../../utils/findPartnerInfo';
import css from './authAndPaymentFlowHeader.module.scss';
import TuneInMarquee from './svgIcons/TuneInMarquee';

const AuthAndPaymentFlowHeader = ({
  header,
  subheader,
  isSignInSignUp,
  billingInfo,
  partners,
  isDesktop,
  isPartnerFlow,
  isPairingFlow,
}) => {
  const isPartnerOrPairingFlow = isPartnerFlow || isPairingFlow;
  const { getLocalizedText, location } = useContext(
    LocationAndLocalizationContext,
  );
  const partnerInfo = findPartnerInfo(location?.query, partners);
  const partnerName = partnerInfo?.displayName;

  return (
    <div
      data-testid="paymentFlowFormLayoutHeaderContainer"
      className={classnames(css.headerContainer, {
        [css.isPartnerOrPairingFlow]: isPartnerOrPairingFlow,
      })}
    >
      {isDesktop && (
        <div id="tuneInLogoContainer" className={css.marqueeContainer}>
          <TuneInMarquee
            width="112px"
            height="50px"
            fill={cssVariables['--ink-dark']}
          />
        </div>
      )}
      <h1
        data-testid="paymentFlowFormLayoutHeader"
        className={classnames(css.header, {
          [css.isPartnerFlow]: isPartnerFlow,
          [css.isPairingFlow]: isPairingFlow,
          [css.signInOrSignUp]: isSignInSignUp,
        })}
      >
        {getLocalizedText(
          header,
          isPartnerOrPairingFlow
            ? {
                partnerName: partnerName || getLocalizedText(DEVICE),
              }
            : undefined,
        )}
      </h1>
      {subheader && (
        <p
          data-testid="paymentFlowFormLayoutSubHeader"
          className={classnames(css.subheader, {
            [css.isPairingFlow]: isPairingFlow,
            [css.isPartnerFlow]: isPartnerFlow,
          })}
        >
          {billingInfo && billingInfo.paymentType === paymentTypes.other && (
            <span>
              {getLocalizedText(PAYMENT_FLOW_FORM_LAYOUT_ALT_PAYMENT_TEXT)}
            </span>
          )}
          {getLocalizedText(subheader)}
        </p>
      )}
    </div>
  );
};

AuthAndPaymentFlowHeader.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  billingInfo: PropTypes.object,
  partners: PropTypes.object,
  isDesktop: PropTypes.bool,
  isSignInSignUp: PropTypes.bool,
  isPartnerFlow: PropTypes.bool,
  isPairingFlow: PropTypes.bool,
};

AuthAndPaymentFlowHeader.defaultProps = {
  isPartnerFlow: false,
  isPairingFlow: false,
};

function mapStateToProps(state) {
  return {
    isDesktop: state?.app?.isDesktop,
    partners: selectPartners(state),
  };
}

export default connect(mapStateToProps)(AuthAndPaymentFlowHeader);
