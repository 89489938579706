import cssVars from '../../../styles/variables';

const partnerAuthSharedStyles = {
  textField: {
    backgroundColor: 'unset',
  },
  textFieldInput: {
    color: cssVars['--off-white'],
    height: '30px',
    fontFamily: 'inherit',
    fontSize: '16px',
    padding: '0px',
    textAlign: 'left',
    border: 'none',
    fontWeight: '300',
    borderBottom: `1px solid ${cssVars['--ink-med-dark']}`,
  },
  labelStyle: {
    color: cssVars['--off-white'],
  },
  floatingLabelShrink: {
    fontSize: '16px',
    textAlign: 'left',
    top: 2,
    left: 0,
    color: cssVars['--off-white'],
  },
  textFieldInputFocus: {
    border: 'none',
  },
  textFieldErrorState: {
    border: 'none',
  },
  inlineErrors: {
    textAlign: 'left',
  },
  textFieldBackgroundStyle: {
    backgroundColor: `${cssVars['--ink-11']}`,
  },
  selectFieldStyle: {
    width: '100%',
    borderTop: 'none',
    fontWeight: '300',
    borderRight: 'none',
    borderBottom: `1px solid ${cssVars['--ink-med-dark']}`,
    borderLeft: 'none',
    color: `${cssVars['--off-white']}`,
    backgroundColor: `${cssVars['--ink-11']}`,
    fontSize: '18px',
    fontFamily: `${cssVars['--auxiliary-font-header']}`,
    height: '33px',
    boxSizing: 'border-box',
    marginTop: '0px',
    paddingLeft: '0px',
  },
  selectLabelStyle: {
    lineHeight: '18px',
    color: `${cssVars['--off-white']}`,
    fontSize: '12px',
  },
};

export const inputMappedPartnerAuthStyles = {
  style: partnerAuthSharedStyles.textField,
  inputStyle: partnerAuthSharedStyles.textFieldInput,
  inputFocusStyle: partnerAuthSharedStyles.textFieldInputFocus,
  floatingLabelStyle: partnerAuthSharedStyles.floatingLabelShrink,
  floatingLabelShrinkStyle: partnerAuthSharedStyles.floatingLabelShrink,
  inputErrorStyle: partnerAuthSharedStyles.textFieldErrorState,
  inlineErrorStyles: partnerAuthSharedStyles.inlineErrors,
};

export const inputCheckmarkSvgProps = {
  width: '20px',
  height: '15px',
  viewBox: '0 0 13 11',
  fill: cssVars['--off-white'],
};

export const selectElementStyling = {
  selectFieldStyle: partnerAuthSharedStyles.selectFieldStyle,
  selectLabelStyle: partnerAuthSharedStyles.selectLabelStyle,
};

export default partnerAuthSharedStyles;
