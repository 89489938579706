import get from 'lodash/get';
import parseQuery from '../../utils/queryString/parse';

// checking if
//  the product requires billing info or
//  if a sku is present or
//  if there are multiple products, in which case we don't want the no payment flow
export default function isPaymentRequired(product, location) {
  const query = parseQuery(location?.search);
  const sku = get(query, 'sku');
  return !!product?.requiresBillingInfo || !sku || Array.isArray(sku);
}
