const paymentTypes = {
  creditCard: 'credit_card',
  other: 'braintree_v_zero',
};

export const stripePaymentTypeMap = {
  card: paymentTypes.creditCard,
};

export default paymentTypes;
