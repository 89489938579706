import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SUBSCRIBE_AGREEMENT } from 'src/common/constants/localizations/subscription';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectTermsOfServiceLink } from '../../selectors/config';
import generateOriginLabelFromQuery from '../../utils/generateOriginLabelFromQuery';
import getOverrideOrDefaultResourceKey from '../../utils/getOverrideOrDefaultResourceKey';
import ExternalLink from '../shared/link/ExternalLink';
import css from './payment.module.scss';

const PaymentDisclaimer = ({ isPartnerOrPairingFlow }) => {
  const { getLocalizedText, location } = useContext(
    LocationAndLocalizationContext,
  );
  const source = generateOriginLabelFromQuery(location);
  const termsOfServiceLink = useSelector(selectTermsOfServiceLink);

  return (
    <div
      className={classNames(css.footer, {
        [css.isPartnerOrPairingFlow]: isPartnerOrPairingFlow,
      })}
      data-testid="paymentDisclaimer"
    >
      {interpolateComponents({
        mixedString: getLocalizedText(
          getOverrideOrDefaultResourceKey(
            getLocalizedText,
            location,
            SUBSCRIBE_AGREEMENT,
          ),
        ),
        components: {
          a1: (
            <ExternalLink
              dataTestId="termsOfServiceLink"
              href={termsOfServiceLink}
              className={classNames(css.legalLink, {
                [css.isPartnerOrPairingFlow]: isPartnerOrPairingFlow,
              })}
            />
          ),
          a2: (
            <ExternalLink
              dataTestId="privacyPolicyLink"
              href="https://tunein.com/policies/privacy/"
              className={classNames(css.legalLink, {
                [css.isPartnerOrPairingFlow]: isPartnerOrPairingFlow,
              })}
            />
          ),
          a3: (
            <ExternalLink
              data-testid={`${source}Link`}
              href={`https://tunein.com/policies/${source}/`}
              className={classNames(css.legalLink, {
                [css.isPartnerOrPairingFlow]: isPartnerOrPairingFlow,
              })}
            />
          ),
        },
      })}
    </div>
  );
};

PaymentDisclaimer.propTypes = {
  isPartnerOrPairingFlow: PropTypes.bool,
};

export default PaymentDisclaimer;
