import PropTypes from 'prop-types';
import { Component } from 'react';
import withFlattenedProps from './withFlattenedProps';

export default function withCheckedInput(CheckedInputComponent) {
  class WithCheckedInput extends Component {
    static propTypes = {
      defaultChecked: PropTypes.bool,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    };

    state = {
      checked:
        this.props.defaultChecked ||
        this.props.checked ||
        this.props.value === true ||
        (this.props.selectedValue &&
          this.props.selectedValue === this.props.value) ||
        false,
    };

    componentDidUpdate(prevProps) {
      const { selectedValue, value } = this.props;

      if (selectedValue !== prevProps.selectedValue) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ checked: selectedValue === value });
      }
    }

    handleClick = (e) => {
      const { onChange } = this.props;

      if (onChange) {
        onChange(e, this.props.value);
      }

      this.setState({ checked: e.target.checked });
    };

    render() {
      const { checked } = this.state;

      return (
        <CheckedInputComponent
          handleClick={this.handleClick}
          {...this.props}
          defaultChecked={checked}
        />
      );
    }
  }

  return withFlattenedProps(WithCheckedInput);
}
