import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { AUTH_PAIRING_PROMO_FINE_PRINT } from 'src/common/constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';

import css from './auth-flow-promo-offer-fine-print.module.scss';

const AuthFlowPromoOfferFinePrint = ({ containerClassName }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <div
      data-testid="authFlowPromoOfferFinePrintContainer"
      className={classNames(css.finePrintContainer, containerClassName)}
    >
      <span data-testid="authFlowPromoOfferFinePrint" className={css.finePrint}>
        {getLocalizedText(AUTH_PAIRING_PROMO_FINE_PRINT)}
      </span>
    </div>
  );
};

AuthFlowPromoOfferFinePrint.propTypes = {
  containerStyle: PropTypes.object,
};

export default AuthFlowPromoOfferFinePrint;
