import classNames from 'clsx';
import PropTypes from 'prop-types';

import withCheckedInput from '../../../decorators/form/withCheckedInput';
import CheckedInput from './CheckedInput';

import css from './radio-button.module.scss';

export const RadioButton = ({ spanClassName, ...restProps }) => (
  <CheckedInput
    spanClassName={classNames(css.span, spanClassName)}
    iconClassName={css.icon}
    type="radio"
    {...restProps}
  />
);

RadioButton.propTypes = {
  spanClassName: PropTypes.string,
};

export default withCheckedInput(RadioButton);
